<template>
  <div>
    <common-header></common-header>
    <div class="confirm-order-box warp">
      <!-- 地址 start -->
      <div class="address-box">
        <router-link to="/account/addressindex?address=1" class="l" v-if="address">
          <div class="">
            <span class="name">{{address.receiveName}}</span>
            <span class="phone">{{address.receivePhone}}</span>
          </div>
          <div class="address-ms">{{address.regionName}}{{address.address}}</div>
        </router-link>
        <router-link to="/account/edit?type=1" class="add-address" v-else>
          <img class="img" src="@/assets/images/img/address-add.png" alt="">
          添加地址
        </router-link>
        <div class="icon-hui-arrow-right"></div>
      </div>
      <!-- 地址 end -->

      <!-- 商品列表 start -->
      <ul class="goods-list" v-if="lists.length > 0">
        <div class="commodity-ul">
          <div class="commodity-item" v-for="(item2, index2) in lists" :key="index2">
            <img class="commodity-img" :src="noImgSet(item2.image)" alt="">
            <div class="right">
              <div class="commodity-details">
                <h4 class="name">{{item2.name}}</h4>
                <p class="commodity-ms">{{item2.specName}}</p>
              </div>
              <div class="commodity-num-box">
                <div class="num">x{{item2.quantity}}</div>
                <div class="money" v-if="item2.price > 0 || (item2.seckill && item2.seckill.seckillPrice > 0)">
                  <span class="deposit" v-if="item2.deposit > 0">(含押金{{item2.deposit}}元)</span>
                  <span class="unit">￥</span>{{item2.seckill ? item2.seckill.seckillPrice + item2.deposit : Number(item2.price) + item2.deposit}}
                </div>
                <div class="is-certified" v-else>{{isCertified()}}</div>
              </div>
            </div>
          </div>
        </div>


        <!-- 总计 start -->
        <div class="total-box" v-if="killNum > 0 || coupons != '' || amountData.deductBalance != 0 || amountData.freightPrice || Vip && Vip != '' && (amountData.discountDetail && amountData.discountDetail.vipDiscount > 0) ">
          <div class="item" v-if="amountData.freightPrice">
            <div class="il">配送费</div>
            <div class="ir"><span class="unit">￥</span>{{amountData.freightPrice}}</div>
          </div>
          <div class="item" v-if="amountData.deductBalance != 0">
            <div class="il">余额抵扣</div>
            <div class="ir"><span class="unit">{{amountData.deductBalance < 0 ? '+' : '-'}}￥</span>{{Math.abs(amountData.deductBalance)}}</div>
          </div>
          <div class="item" v-if="Vip && Vip != '' && (amountData.discountDetail && amountData.discountDetail.vipDiscount > 0)">
            <div class="il">会员优惠</div>
            <div class="ir active-color">- <span class="unit">￥</span>{{amountData.discountDetail.vipDiscount}}</div>
          </div>
          <div class="item" v-if="coupons != ''">
            <div class="il">优惠券</div>
            <div class="copus-xx" @click="$router.push(`/account/coupons?id=${coupons[copIndex].id}`)">
              <div class="ir active-color">满{{coupons[copIndex].useConditionAmount}}减{{coupons[copIndex].amount}}</div>
              <i class="icon-hui-arrow-right"></i>
            </div>
          </div>
          <div class="tips" v-if="killNum > 0"><i class="tips-icon"></i>秒杀商品不参与满减</div>
          
        </div>
        <!-- 总计 end -->
        <div class="detailed">
          <!-- <span class="ms" v-if="amountData.discountDetail && amountData.discountDetail.discountsAmount > 0">已优惠<b class="active-color">￥{{amountData.discountDetail.discountsAmount}}</b></span> -->
          <span class="ms">合计：<span class="shifu active-color">￥<b>{{amountData.realAmount}}</b></span></span>
        </div>
      </ul>
      <!-- 商品列表 end -->

      <!-- 订单备注 start -->
      <div class="order-reamks">
        <div class="name">订单备注</div>
        <textarea v-model="remark" placeholder="请输入订单备注"></textarea>
      </div>
      <!-- 订单备注 end -->
    </div>



    <!-- 支付二维码弹窗start -->
    <mt-popup
      position="center"
      class="w90"
      v-model="qrcodePop"
    >
      <div class="qrcode-box">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="text">请扫描二维码支付</div>
      </div>
    </mt-popup>
    <!-- 支付二维码弹窗end -->

    <!-- 支付是否成功start -->
    <PayPop :orderCode="orderCode" @changePayPop="changePayPop" :price=amountData.realAmount :payTypePop="payTypePop"></PayPop>
    <!-- 支付是否成功start -->


    <!-- 底部 start -->
    <div class="fixed-bottom-box">
      <div class="fixed-bottom">
        <div class="l">
          <div class="txt">实付款</div>
          <div class="price">
            <div class="money"><span class="unit">￥</span>{{amountData.realAmount}}</div>
          </div>
        </div>
        <div class="pay-btn jb-r-l" @click="mallPay">立即支付</div>
      </div>
    </div>
    <!-- 底部 end -->
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import QRCode from 'qrcodejs2';
import PayPop from '@/components/global/PayPop'
import { requestImgUrl, isWeiXin, funcUrlDel, noImgSet, onBridgeReadyStart} from "@/utils/common";
import { isHttp, isCertified } from "@/utils/utils";

export default {
  created() {
    this.cgAddressId = localStorage.getItem('cgAddress');
    //  优惠券id
    this.CouponId = localStorage.getItem('CouponId');
    localStorage.removeItem('CouponId');
  },
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.noImgSet = (url) => noImgSet(url);
    this.isCertified = () => isCertified();
    return {
      // 地址id
      cgAddressId: "",
      CouponId: "",
      lists: [],
      address: "",
      arrs: [],
      // 二维码弹窗
      qrcodePop: false,
      // 显示支付状态弹窗
      payTypePop: false,
      // 订单编号
      orderCode: "",
      amountData: "",
      // vip信息
      Vip: "",
      // 优惠券信息
      coupons: "",
      // 当前优惠券索引值 默认第一张,
      copIndex: 0,
      // 是否有描述商品
      killNum: 0,
      // 订单备注
      remark: '',
      detail:''
    }
  },
  components:{
    'PayPop': PayPop
  },
  mounted() {
    this.lists = JSON.parse(localStorage.getItem('orderCommodity'));
    this.totalPrice();
    this.getAddressList();
    // VIP标识
    this.Vip = sessionStorage.getItem("VIP") ? JSON.parse(sessionStorage.getItem("VIP")) : '';

  },
  beforeDestroy() {
    localStorage.removeItem('cgAddress')
  },

  methods: {
    getAddressList() {
      this.userAddressList({
        v: new Date().getTime(),
      }).then((res)=> {
        if (this.cgAddressId) {
          let addressAry = res.data.filter((e)=> e.id ==  this.cgAddressId);
          if (addressAry.length == 0) {
            this.address = res.data[0];
          } else{
            this.address = addressAry[0];
          }
        } else {
          this.address = res.data[0];
        }
      });
    },
    ...mapActions("guide", [
      "userAddressList",
    ]),
    ...mapActions("shopping", [
      "placeOrder",
      'CountAmount',
      "postOrderLink",
      "getOrderPayType",
    ]),
    changePayPop(e) {
      this.payTypePop = e;
    },
    // 生成二维码
    creatQrCode(text) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: text, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
      this.qrcodePop = true;
      this.$Indicator.close();
    },
    // 支付
    mallPay() {
      if (!this.address) {
        this.$toast("请添加收货地址");
        return false;
      }
      // if (!isWeiXin()) {
      //   this.$toast("请在微信中打开页面支付！");
      //   return false;
      // }

      this.$Indicator.open({
        text: '检查支付环境...',
        spinnerType: 'snake',
      });
      this.placeOrder({
        addressId: this.address.id,
        detail: JSON.stringify(this.arrs),
        couponsId: this.coupons.length > 0 ? this.coupons[this.copIndex].id : '',
        remark: this.remark,
      }).then((res)=> {
        this.$Indicator.close();
        this.orderCode = res.data.orderCode;
        if (res.data.totalPrice > 0) {
          this.postOrderLink({
            orderCode: res.data.orderCode,
            paySource: "WECHAT"
          }).then((res2)=> {
            if(isWeiXin()) {
              onBridgeReadyStart(JSON.parse(res2.data.payParam), (res3)=> {
                this.$router.push({
                  path: '/shopping/payment', 
                  query: {orderCode: res.data.orderCode, price: this.amountData.realAmount}
                })
              }, ()=> {
                this.$toast("取消支付");
              });
            }
            // this.creatQrCode(res2.data.payParam);
          })
        } else {
          this.$router.push({
            path: '/shopping/payment', 
            query: {orderCode: res.data.orderCode, price: res.data.totalPrice}
          })
        }
        
      })
    },
    // 计算总价
    totalPrice() {
      //  [{id:商品id,quantity:数量,specId:规格id}
      let {arrs} = this;
      let killNum = 0;
      this.lists.forEach((e)=> {
        if (e.seckill) {
          killNum++;
        }
        arrs.push({
          id: e.commodityId,
          quantity: e.quantity,
          specId: e.specId,
        });
      });
      this.killNum = killNum;
      this.detail = JSON.stringify(arrs);
      localStorage.setItem("orderDetail",this.detail);
      this.CountAmount({
        detail: this.detail,
        couponsId: this.CouponId,
      }).then((res)=> {
        this.amountData = res.data;
        let couponsList = res.data.discountDetail ? res.data.discountDetail.couponsList : [];
        if (this.CouponId) {
          this.coupons = couponsList.filter((e)=> e.id == this.CouponId);
        } else {
          this.coupons = couponsList;
        }
      })
    },
  },
  destroyed() {
    localStorage.removeItem('cgAddress');
    localStorage.removeItem('CouponId');
  },
}
</script>
<style lang='less' scoped>
.deposit{
  color: #ccc;
  font-size: .24rem;
  margin-right: .15rem;
}
.order-reamks{
  background: #fff;
  margin-top: .2rem;
  padding: .2rem;
  border-radius: .1rem;
  .name{
    font-size: .28rem;
  }
  textarea{
    width: 100%;
    height: 2rem;
    background: #f5f5f5;
    border-radius: .1rem;
    font-size: .28rem;
    display: block;
    margin-top: .2rem;
    padding: .2rem;
  }
}
.tips{
  color: #8c8c8c;
  font-size: .24rem;
  margin-top: .2rem;
  display: flex;
  align-items: center;
  .tips-icon{
    background-image: url('../../assets/images/img/tips-gth.png');
    width: .26rem;
    height: .26rem;
    background-size: 100%;
    margin-right: .1rem;
  }
}

.detailed{
  text-align: right;
  font-size: .28rem;
  color: #333;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f7f7f7;
  padding: .25rem 0;
  align-items: center;
  .ms{
    margin-left: .2rem;
    display: flex;
    align-items: center;
  }
  .shifu{
    display: flex;
    align-items: flex-end;
    line-height: 1;
    b{
      font-size: .32rem;
      font-weight: normal;
    }
  }
}
.qrcode-box{
  .qrcode{
    padding: .2rem;
    display: flex;
    justify-content: center;
  }
  text-align: center;
  padding: .4rem 0;
}

.add-address{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .3rem;
  height: 100%;
  color: #333;
  .img{
    margin-right: .2rem;
    width: .6rem;
  }
}
.fixed-bottom-box{
  height: 1.13rem;
}
.fixed-bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 1.13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: .38rem;
  padding-right: .38rem;
  .pay-btn{
    width: 2.4rem;
    height: .78rem;
    line-height: .78rem;
    text-align: center;
    font-size: .32rem;
    color: #ffffff;
    font-weight: bold;
    border-radius: .39rem;
  }
  .org-price{
    text-decoration: line-through;
    font-size: .28rem;
    color: #bfbfbf;
    margin-left: .2rem;
  }
  .price{
    margin-top: 0;
    margin-left: .15rem;
    .money{
      font-size: .46rem;
    }
  }
  .l{
    display: flex;
    align-items: center;
  }
  .txt{
    color: #262626;
    font-size: .26rem;
  }
}
.total-box{
  border-top: 1px solid #f7f7f7;
  padding-top: .2rem;
  padding-bottom: .2rem;
  .unit{
    font-size: .24rem;
  }
  .il{
    font-size: .26rem;
  }
  .ir{
    font-size: .32rem;
    display: flex;
    align-items: flex-end;
    line-height: 1;
  }
  .copus-xx{
    display: flex;
    align-items: center;
    .ir{
      margin-right: .1rem;
    }
  }
  .item{
    font-size: .28rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    height: .5rem;
    align-items: center;
  }
  .price{
    margin-top: 0;
    margin-left: .1rem;
  }
}
.price{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .4rem;
  .num{
    font-size: .26rem;
    color: #727272;
  }
  .unit{
    font-size: .24rem;
  }
  .money{
    font-size: .32rem;
    color: #ff7241;
    font-weight: 700;
  }
}
.goods-list{
  padding-left: .3rem;
  padding-right: .2rem;
  background: #fff;
  margin-top: .2rem;
  padding-top: .3rem;
  border-radius: .1rem;
  overflow: hidden;
  li{
    display: flex;
    margin-bottom: .3rem;
    .goods-info{
      flex: 1;
      .name{
        color: #262626;
        font-size: .3rem;
        font-weight: 700;
      }
      .ms{
        font-size: .26rem;
        color: #bfbfbf;
        margin-top: .05rem;
      }
    }
  }
  .goods-img{
    width: 1.52rem;
    height: 1.52rem;
    border-radius: .05rem;
    margin-right: .5rem;
  }
}
.address-box{
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: .1rem;
  padding: .3rem .2rem ;
  box-sizing: border-box;
  height: 2rem;
  .address-ms{
    color: #727272;
    font-size: .28rem;
    padding-right: .4rem;
    margin-top: .2rem;
  }
  .name{
    color: #262626;
    font-size: .32rem;
    margin-right: .2rem ;
  }
  .phone{
    color: #262626;
    font-size: .32rem;
  }
  .l{
    flex: 1;
  }
}
.confirm-order-box{
  padding-top: .4rem;
  background:url('../../assets/images/img/car-bj.png') no-repeat;
  background-size: 100%;
  min-height: 2rem;
  background-size: 100%;
  background-position-y: -.2rem;
  padding-bottom: .5rem;
}

</style>